import { Component } from "@angular/core";
import { LayoutService } from "src/app/services/layouts/layouts.service";

@Component({
    selector:'app-userquestdashboard',
    templateUrl:'./userQuestDashboard.component.html',
    styleUrls:['./userQuestDashboard.component.css']
})

export class UserQuestDashboard{
    constructor(private layoutService:LayoutService){
        layoutService.title="BIENVENIDO"
    }
}